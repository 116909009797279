
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import Logo from '@/assets/svg/logo.svg?inline'
import AlertBlock from '@/components/molecules/AlertBlock.vue'

export default defineComponent({
  name: 'PasswordForgotSection',
  data () {
    const schema = yup.object().shape({
      email: yup.string().required('Email is required!')
    })
    const schemaCode = yup.object().shape({
      code: yup.string().required('Email is required!')
    })

    return {
      isModalOpen: false,
      schema,
      schemaCode,
      message: '',
      errorCode: '',
      isCode: false
    }
  },
  components: {
    Form,
    Field,
    AlertBlock,
    ErrorMessage,
    Logo
  },
  methods: {
    sendEmail (data) {
      this.$store.dispatch('auth/passwordRecoveryEmail', data).then(() => {
        this.isCode = true
      },
      (error) => {
        this.message = error.response.data.errors
      })
    },
    sendCode (data) {
      this.$store.dispatch('auth/sendCode', data).then(() => {
        localStorage.setItem('code', JSON.stringify(data.code))
        this.$router.push('/new-password')
      },
      (error) => {
        this.errorCode = error.response.data.errors
      })
    }
  }
})
