
import { defineComponent } from 'vue'
import PasswordForgotSection from '@/components/sections/PasswordForgotSection.vue'

export default defineComponent({
  name: 'PasswordForgot',
  components: {
    PasswordForgotSection
  }
})
